var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var DX,Pna,Qna,Rna,GX,Tna,Una,Vna,Wna,IX,JX,KX,MX,Xna,NX,OX,Yna,Zna,$na,PX,Sna,QX,RX,aoa,TX;DX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.EX=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,A){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,A){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M(a,u,v,x,A),$CLJS.Ne.M(b,u,v,x,A)],null)}m.A=3;m.B=function(u){var v=$CLJS.z(u);u=$CLJS.B(u);var x=$CLJS.z(u);u=$CLJS.B(u);var A=$CLJS.z(u);u=$CLJS.Hc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.FX=function(a,b){return $CLJS.QK(a)(b,$CLJS.xf,$CLJS.xf)};
Pna=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.bF,b),$CLJS.Xr(2,2,c))};Qna=function(a,b){var c=$CLJS.Dl(a,$CLJS.FP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.hs.g?$CLJS.hs.g(c,b):$CLJS.hs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.FP,b)}return a};Rna=function(a){return $CLJS.bf.g($CLJS.LU(a),$CLJS.MU(a))};
GX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Er,null,$CLJS.Rk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.st(2,c):$CLJS.st(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?DX(2,c):DX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.vW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.ud(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.fk.h($CLJS.Yk),l):$CLJS.sd(k)?$CLJS.Jz($CLJS.Ta,l):l}):$CLJS.Xj.g(a,f)};
Tna=function(a){return $CLJS.bb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return GX(b,Sna,d)},a,Rna(a))};
Una=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.sK.h(a),b);a:{var d=c;for(var e=$CLJS.xf;;){var f=$CLJS.z($CLJS.cf.g($CLJS.EX($CLJS.Ri,$CLJS.Mk),$CLJS.ff($CLJS.ek.j(HX,$CLJS.z,$CLJS.Mk),$CLJS.Ir.h($CLJS.FX($CLJS.kV,d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=GX(d,f,k),m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.convert",m))){var t=$CLJS.BA.l($CLJS.H([$CLJS.yy,$CLJS.xh.l($CLJS.H([k]))])),u=$CLJS.BA,v=u.l,x=$CLJS.xh,A=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.VK($CLJS.FX($CLJS.kV,
d));$CLJS.Kz("metabase.lib.convert",m,$CLJS.tV("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.yy,A.call(x,$CLJS.H([C]))])),$CLJS.BA.l($CLJS.H([$CLJS.ny,$CLJS.uV($CLJS.z($CLJS.Zs(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.Yd.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=Tna(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.sK.h(a))-1))return a;b+=1}else a=$CLJS.hk.M(a,$CLJS.sK,$CLJS.R,b,d)}};
Vna=function(a){var b=$CLJS.HV();return $CLJS.gk.g(function(c){return $CLJS.E.g($CLJS.YD.h(c),"__join")?$CLJS.hk.j(c,$CLJS.YD,b):c},a)};Wna=function(a){return"string"===typeof $CLJS.eO.h(a)?$CLJS.Xj.g($CLJS.R.j(a,$CLJS.NU,$CLJS.FV($CLJS.eO.h(a))),$CLJS.eO):a};IX=function(){return $CLJS.fk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};
JX=function(a){return $CLJS.Wf.j($CLJS.N,IX(),a)};KX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.ek.g(IX(),$CLJS.fk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.oi)})),a))};
MX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.LX),c);a=$CLJS.E.g(a,$CLJS.VE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.nd(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.md(d)],null)):d;b=$CLJS.Be(KX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uL,a,b],null):a};Xna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.ek.g($CLJS.cf.h(function(b){return $CLJS.Ez(b,$CLJS.GA)}),$CLJS.cf.h($CLJS.LX)),$CLJS.BV.h(a))};
NX=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.sK);a=$CLJS.z($CLJS.bb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.LX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.HP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.RN,Xna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.nU.h(c)],null)},null,a));return $CLJS.n($CLJS.iP.h(a))?$CLJS.yU(a,new $CLJS.h(null,1,[$CLJS.iP,$CLJS.IE],null)):a};
OX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.SV(a,b,$CLJS.ek.g($CLJS.LX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.SV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lr],null),$CLJS.cf.h($CLJS.LX),e)}):d;return $CLJS.yU(a,$CLJS.Ae([b,c]))};Yna=function(a){var b=$CLJS.NU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Xj.g(a,$CLJS.NU),$CLJS.eO,["card__",$CLJS.p.h(b)].join("")):a};
Zna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);$na=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);PX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Sna=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);QX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
RX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.SX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);aoa=new $CLJS.M(null,"m","m",1632677161);TX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var HX,boa,coa,doa,eoa,foa,goa,hoa,ioa,joa,koa;$CLJS.UX=$CLJS.N;$CLJS.VX=$CLJS.N;HX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.hV,null,$CLJS.UD,null,$CLJS.DM,null,$CLJS.ZO,null,$CLJS.aF,null,$CLJS.NQ,null,$CLJS.bF,null],null),null);boa=$CLJS.Se($CLJS.N);coa=$CLJS.Se($CLJS.N);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.Se($CLJS.N);foa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ek,$CLJS.hB],null),$CLJS.Ek,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.WX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.YA,foa,boa,coa,doa,eoa);$CLJS.WX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.md(a);var c=$CLJS.sd(b);a=c?$CLJS.nd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.oV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.WX),d))}else b=a;return b});$CLJS.WX.m(null,$CLJS.gV,function(a){return a});
$CLJS.WX.m(null,$CLJS.UU,function(a){var b=$CLJS.WX.h($CLJS.bF.h(a)),c=$CLJS.Be($CLJS.gk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.yV($CLJS.WX.h(t),u)},$CLJS.ZO.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.AD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.VX,k=$CLJS.UX;$CLJS.VX=d;$CLJS.UX=e;try{var l=Pna(Wna(a),b,$CLJS.H([$CLJS.ZO,c])),m=$CLJS.bb(function(t,u){return $CLJS.Sa($CLJS.J.g(t,u))?t:$CLJS.hk.j(t,u,$CLJS.WX)},l,$CLJS.Yj.l(HX,$CLJS.bF,$CLJS.H([$CLJS.ZO])));return $CLJS.n($CLJS.DM.h(m))?$CLJS.hk.j(m,$CLJS.DM,Vna):m}finally{$CLJS.UX=k,$CLJS.VX=f}});$CLJS.WX.m(null,$CLJS.aV,function(a){return Qna(a,function(b){return $CLJS.SV(b,$CLJS.FE,$CLJS.WX)})});
$CLJS.WX.m(null,$CLJS.nK,function(a){a=$CLJS.hk.j($CLJS.hk.j(a,$CLJS.vK,$CLJS.WX),$CLJS.sK,$CLJS.WX);var b=$CLJS.n($CLJS.UD.h(a))?$CLJS.hk.j(a,$CLJS.UD,function(c){return $CLJS.jl(c)?$CLJS.gk.g($CLJS.WX,c):$CLJS.mh.h(c)}):a;return $CLJS.Sa($CLJS.YD.h(a))?$CLJS.R.j(b,$CLJS.YD,"__join"):b});$CLJS.WX.m(null,$CLJS.nA,function(a){return $CLJS.gk.g($CLJS.WX,a)});
$CLJS.WX.m(null,$CLJS.pA,function(a){return $CLJS.n($CLJS.Ri.h(a))?Una($CLJS.R.j($CLJS.hk.j($CLJS.CV(a),$CLJS.sK,function(b){return $CLJS.gk.g($CLJS.WX,b)}),$CLJS.SX,!0)):$CLJS.hs(a,$CLJS.WX)});$CLJS.WX.m(null,$CLJS.mF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,a,b],null))});
$CLJS.WX.m(null,$CLJS.ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.yU(a,new $CLJS.h(null,3,[$CLJS.dC,$CLJS.tA,$CLJS.DN,$CLJS.sj,$CLJS.VQ,$CLJS.uD],null));var c=$CLJS.R.j;var d=$CLJS.oi.h(a);$CLJS.n(d)||(d=$CLJS.tA.h(a),d=$CLJS.n(d)?d:$CLJS.PD(b));a=c.call($CLJS.R,a,$CLJS.oi,d);return $CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,a,b],null))});
$CLJS.WX.m(null,$CLJS.VE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VE,$CLJS.Xj.g(c,$CLJS.Oh),$CLJS.gk.g($CLJS.WX,b)],null);b=$CLJS.oV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.WX.h(a)):b});$CLJS.WX.m(null,$CLJS.qA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.WX.m(null,$CLJS.bF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.VX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.YQ,a],null));return $CLJS.oV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.WX.m(null,$CLJS.uL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.WX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.wk.l($CLJS.H([c,a]))],null),d)});goa=$CLJS.Se($CLJS.N);hoa=$CLJS.Se($CLJS.N);ioa=$CLJS.Se($CLJS.N);joa=$CLJS.Se($CLJS.N);koa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ek,$CLJS.hB],null),$CLJS.Ek,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.LX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.YA,koa,goa,hoa,ioa,joa);
$CLJS.LX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.sd(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.LX,d);a=KX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.LX,$CLJS.ae(a,d)))}else a=$CLJS.sd(a)?$CLJS.hs(JX(a),$CLJS.LX):
a;return a});for(var XX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[TX,RX],null)),YX=null,ZX=0,$X=0;;)if($X<ZX){var loa=YX.X(null,$X);$CLJS.sE(loa,QX);$X+=1}else{var aY=$CLJS.y(XX);if(aY){var bY=aY;if($CLJS.vd(bY)){var cY=$CLJS.ic(bY),moa=$CLJS.jc(bY),noa=cY,ooa=$CLJS.D(cY);XX=moa;YX=noa;ZX=ooa}else{var poa=$CLJS.z(bY);$CLJS.sE(poa,QX);XX=$CLJS.B(bY);YX=null;ZX=0}$X=0}else break}
for(var dY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Rw,$CLJS.gF,$CLJS.vF,$CLJS.RE,$CLJS.Bj,$CLJS.zF,$CLJS.si,$CLJS.YE,$CLJS.tF,$CLJS.AF,$CLJS.yF,$CLJS.FF],null)),eY=null,fY=0,gY=0;;)if(gY<fY){var qoa=eY.X(null,gY);$CLJS.sE(qoa,TX);gY+=1}else{var hY=$CLJS.y(dY);if(hY){var iY=hY;if($CLJS.vd(iY)){var jY=$CLJS.ic(iY),roa=$CLJS.jc(iY),soa=jY,toa=$CLJS.D(jY);dY=roa;eY=soa;fY=toa}else{var uoa=$CLJS.z(iY);$CLJS.sE(uoa,TX);dY=$CLJS.B(iY);eY=null;fY=0}gY=0}else break}
for(var kY=$CLJS.y($CLJS.tf([$CLJS.Jq,$CLJS.$r,$CLJS.Kq,$CLJS.GF,$CLJS.VE,$CLJS.pF,$CLJS.ME,$CLJS.IF,$CLJS.DE,$CLJS.$E,$CLJS.HE,$CLJS.kF,$CLJS.rF,$CLJS.JE,$CLJS.$A,$CLJS.aB,$CLJS.Fx,$CLJS.ZH,$CLJS.dI,$CLJS.TH,$CLJS.bB,$CLJS.YH,$CLJS.RH,$CLJS.gI,$CLJS.lI,$CLJS.iI,$CLJS.XH,$CLJS.nI,$CLJS.cB,$CLJS.fB,$CLJS.LE,$CLJS.EE,$CLJS.JF,$CLJS.ZG,$CLJS.oF,$CLJS.Pw,$CLJS.BF,$CLJS.DF,$CLJS.KF,$CLJS.ks,$CLJS.qs],!0)),lY=null,mY=0,nY=0;;)if(nY<mY){var voa=lY.X(null,nY);$CLJS.sE(voa,RX);nY+=1}else{var oY=$CLJS.y(kY);
if(oY){var pY=oY;if($CLJS.vd(pY)){var qY=$CLJS.ic(pY),woa=$CLJS.jc(pY),xoa=qY,yoa=$CLJS.D(qY);kY=woa;lY=xoa;mY=yoa}else{var zoa=$CLJS.z(pY);$CLJS.sE(zoa,RX);kY=$CLJS.B(pY);lY=null;mY=0}nY=0}else break}$CLJS.LX.m(null,QX,function(a){return MX(a)});$CLJS.LX.m(null,$CLJS.pA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.ek.g(IX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.LX.h(b)],null)})),a)});
$CLJS.LX.m(null,$CLJS.bF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.sd(b))try{var d=KX(b);b=$CLJS.Q;var e=$CLJS.UX,f=$CLJS.J.j(e,c,PX);if($CLJS.E.g(f,PX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[aoa,e,$CLJS.Vv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.bF,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uL,k,d],null):k}catch(l){throw c=l,d=$CLJS.sU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.LX.m(null,$CLJS.nA,function(a){return $CLJS.gk.g($CLJS.LX,a)});
$CLJS.LX.m(null,$CLJS.mF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,$CLJS.LX.h(a),KX(b)],null)});
$CLJS.LX.m(null,$CLJS.ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=KX($CLJS.yU(b,new $CLJS.h(null,3,[$CLJS.tA,$CLJS.dC,$CLJS.sj,$CLJS.DN,$CLJS.uD,$CLJS.VQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,a,b],null)});
$CLJS.LX.m(null,$CLJS.nK,function(a){var b=JX(a);a=0==$CLJS.YD.h(a).lastIndexOf("__join",0)?$CLJS.Xj.g(b,$CLJS.YD):b;return $CLJS.wk.l($CLJS.H([$CLJS.hs($CLJS.Xj.l(a,$CLJS.sK,$CLJS.H([$CLJS.vK])),$CLJS.LX),OX($CLJS.Cl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vK],null)),$CLJS.vK,$CLJS.PQ),NX(a)]))});
$CLJS.LX.m(null,$CLJS.UU,function(a){var b=$CLJS.bF.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.VX,e=$CLJS.UX;$CLJS.VX=c;$CLJS.UX=b;try{return $CLJS.bb(function(f,k){return $CLJS.SV(f,
k,$CLJS.LX)},OX($CLJS.SV($CLJS.SV(Yna(JX(a)),$CLJS.bF,function(f){return $CLJS.gk.g(MX,f)}),$CLJS.ZO,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A),G=$CLJS.LX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xV(C),$CLJS.E.g($CLJS.ej,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.LX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xV(x),$CLJS.E.g($CLJS.ej,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.hV,$CLJS.QE),$CLJS.Yj.l(HX,$CLJS.bF,$CLJS.H([$CLJS.hV,$CLJS.ZO])))}finally{$CLJS.UX=e,$CLJS.VX=d}});$CLJS.LX.m(null,$CLJS.aV,function(a){return $CLJS.hs(JX(a),$CLJS.LX)});
$CLJS.LX.m(null,$CLJS.gV,function(a){try{var b=JX(a),c=$CLJS.uM.h(b),d=NX(b),e=$CLJS.E.g($CLJS.fj.h($CLJS.ed($CLJS.sK.h(a))),$CLJS.aV)?$CLJS.iP:$CLJS.IE;return $CLJS.wk.l($CLJS.H([$CLJS.Xj.l(b,$CLJS.sK,$CLJS.H([$CLJS.uM,$CLJS.SX])),function(){var k=$CLJS.Ae([$CLJS.Ri,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.uM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.sU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.IE,a],null),f);}});
$CLJS.rY=function(){function a(d,e,f){f=$CLJS.CX(null,$CLJS.Ly(f,$CLJS.H([$CLJS.ii,!0])));var k=$CLJS.EV(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.bF);k=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.AD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.VX,t=$CLJS.UX;$CLJS.VX=k;$CLJS.UX=l;try{try{return $CLJS.WX.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.sU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.IE,d,$CLJS.FK,e,$na,f,Zna,$CLJS.VX],null),u);}}finally{$CLJS.UX=t,$CLJS.VX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();