var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var CK,EK,GK,JK,MK;$CLJS.AK=function(a){return $CLJS.Hh($CLJS.q($CLJS.hB),a,$CLJS.qF)};$CLJS.BK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);CK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.DK=new $CLJS.M(null,"operators","operators",-2064102509);EK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);
$CLJS.FK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);GK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.HK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.IK=new $CLJS.M(null,"dimensions","dimensions",-254818097);JK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.KK=new $CLJS.M(null,"max-value","max-value",687805168);
$CLJS.LK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);MK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.NK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(EK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.yD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.dG],null)],null)],null));$CLJS.Y(MK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,EK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.DD],null)],null)],null));$CLJS.Y(GK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.mF],null),MK,$CLJS.sD],null));
$CLJS.Y(JK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.mF],null),EK,$CLJS.CD],null));
$CLJS.LF.g($CLJS.mF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.mF],null),EK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.CD,$CLJS.sD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.xi,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.YA(a)):null},$CLJS.us,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,JK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,GK],null)],null)],null));$CLJS.sE($CLJS.mF,$CLJS.qF);$CLJS.OD.m(null,$CLJS.mF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.oi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.aE});$CLJS.zE($CLJS.qA,$CLJS.H([$CLJS.sD]));$CLJS.OD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.oi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.aE});
$CLJS.sE($CLJS.qA,$CLJS.qF);$CLJS.Y(CK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.yD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.sD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.sD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.sD],null)],null)],null));
$CLJS.LF.g($CLJS.bF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.bF],null),CK,$CLJS.vj],null));$CLJS.OD.m(null,$CLJS.bF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.oi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.aE});$CLJS.sE($CLJS.bF,$CLJS.qF);$CLJS.zE($CLJS.KE,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kD],null)],null)]));$CLJS.sE($CLJS.KE,$CLJS.qF);
$CLJS.zE($CLJS.hF,$CLJS.H([$CLJS.$r,$CLJS.aE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iD],null)],null)]));$CLJS.sE($CLJS.hF,$CLJS.qF);
$CLJS.Y($CLJS.qF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.QD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.Fs,function(){return["Valid reference, must be one of these clauses: ",$CLJS.is(", ",$CLJS.Cz($CLJS.Dd,$CLJS.zk.g($CLJS.q($CLJS.hB),$CLJS.qF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.AK(a)}],null)],null));