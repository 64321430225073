var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var x0,xpa,y0,z0;$CLJS.v0=function(a,b){return $CLJS.dc($CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.cc(c,d):c},$CLJS.ac($CLJS.xf),b))};x0=function(a){return $CLJS.n(w0)?["(",$CLJS.p.h(a),")"].join(""):a};xpa=function(a,b){var c=$CLJS.hk.v(a,$CLJS.ZO,$CLJS.RC($CLJS.Yd,$CLJS.xf),b);return $CLJS.ud($CLJS.UD.h(a))?$CLJS.hk.v(c,$CLJS.UD,$CLJS.Yd,$CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.N,$CLJS.xV(b)],null))):c};
y0=function(a,b,c){var d=$CLJS.xV(c);return $CLJS.R.l($CLJS.g_.j(a,b,c),$CLJS.OJ,$CLJS.DY,$CLJS.H([$CLJS.T,d,$CLJS.nD,d]))};z0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.A0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var B0=function(){function a(d,e,f){var k=$CLJS.EV(d,e);k=$CLJS.uG($CLJS.ek.g($CLJS.Tg([f]),$CLJS.xV),$CLJS.ZO.h(k));if($CLJS.n(k))return k;throw $CLJS.Uh($CLJS.gD("No expression named {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.ZQ,f,$CLJS.IE,d,$CLJS.FK,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.N_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=B0.j(a,b,c);return $CLJS.O_.j(a,b,c)});$CLJS.P_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.fj,$CLJS.NJ,$CLJS.A0,$CLJS.AD.h(d),$CLJS.T,e,$CLJS.rD,e,$CLJS.nD,$CLJS.i_.j(a,b,c),$CLJS.tA,$CLJS.O_.j(a,b,c),$CLJS.OJ,$CLJS.DY],null)});$CLJS.J_.m(null,$CLJS.lA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.J_.m(null,$CLJS.jA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.J_.m(null,$CLJS.kA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.J_.m(null,$CLJS.zA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.J_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.K_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var w0=!1,ypa=new $CLJS.h(null,4,[$CLJS.Jq,"+",$CLJS.$r,"-",$CLJS.Kq,"×",$CLJS.GF,"÷"],null),C0=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jq,$CLJS.$r,$CLJS.GF,$CLJS.Kq],null)),D0=null,E0=0,F0=0;;)if(F0<E0){var zpa=D0.X(null,F0);$CLJS.sE(zpa,z0);F0+=1}else{var G0=$CLJS.y(C0);if(G0){var H0=G0;if($CLJS.vd(H0)){var I0=$CLJS.ic(H0),Apa=$CLJS.jc(H0),Bpa=I0,Cpa=$CLJS.D(I0);C0=Apa;D0=Bpa;E0=Cpa}else{var Dpa=$CLJS.z(H0);$CLJS.sE(Dpa,z0);C0=$CLJS.B(H0);D0=null;E0=0}F0=0}else break}
$CLJS.J_.m(null,z0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(ypa,c);a:{var e=w0;w0=!0;try{var f=$CLJS.is([" ",$CLJS.Xg(c)," "].join(""),$CLJS.cf.g($CLJS.Xs($CLJS.i_,a,b),d));break a}finally{w0=e}f=void 0}return x0(f)});$CLJS.K_.m(null,z0,function(){return"expression"});
$CLJS.N_.m(null,$CLJS.BG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Md($CLJS.uE,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=$CLJS.O_.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae($CLJS.O_.j(a,
b,u),k($CLJS.Hc(l)))}return null}},null,null)}(d)}())});$CLJS.J_.m(null,$CLJS.cB,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.i_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.p0.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=$CLJS.yz(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=x0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.K_.m(null,$CLJS.cB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.L_.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.p0.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=$CLJS.yz(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.J_.m(null,$CLJS.pF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.i_.v(a,b,e,d)});$CLJS.K_.m(null,$CLJS.pF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.L_.j(a,b,d)});
$CLJS.Epa=function(){function a(d,e,f,k){return $CLJS.PV.l(d,$CLJS.n(e)?e:-1,xpa,$CLJS.H([$CLJS.yV($CLJS.qV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Fpa=function(){function a(d,e){var f=$CLJS.Be($CLJS.ZO.h($CLJS.EV(d,e)));return null==f?null:$CLJS.gk.g($CLJS.Xs(y0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.J0=function(){function a(d,e){return $CLJS.Be($CLJS.ZO.h($CLJS.EV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.nV.m(null,$CLJS.qA,function(a){return a});
$CLJS.Gpa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.Bl(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xV(t),m],null)},$CLJS.J0.g(d,e))),l=$CLJS.EV(d,e);d=$CLJS.V_.j(d,e,l);return $CLJS.Be($CLJS.v0(function(m){return $CLJS.Sa(f)||$CLJS.dk.g($CLJS.OJ.h(m),$CLJS.DY)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Hpa=function(){function a(d,e,f){return $CLJS.pV(y0(d,e,B0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.K0=$CLJS.pE.h($CLJS.$D);