var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var pva,rva,sva,tva,uva;pva=function(a,b){return $CLJS.dc($CLJS.bb(function(c,d){return $CLJS.Zf.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ac($CLJS.N),b))};rva=function(a){return $CLJS.Wf.j($CLJS.Sg,$CLJS.ek.g($CLJS.fD(function(b){return $CLJS.RV(b,a)}),$CLJS.cf.h($CLJS.dd)),qva)};sva=function(a){return new $CLJS.h(null,3,[$CLJS.Ri,$CLJS.Hj,$CLJS.T,a,$CLJS.Hi,$CLJS.p.h($CLJS.LD())],null)};
tva=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.wk.l($CLJS.H([a,function(){var c=$CLJS.dh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.sV(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.vM,$CLJS.bQ,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.GL,$CLJS.sL,c],null):null}(),$CLJS.n($CLJS.nD.h(a))?null:new $CLJS.h(null,
1,[$CLJS.nD,$CLJS.e1.g($CLJS.xA,b)],null)]))};$CLJS.X8=function(a){a=$CLJS.W0(a);return $CLJS.n($CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dz,uva],null)))?$CLJS.Yd.g($CLJS.Sg,$CLJS.lN):$CLJS.Sg};$CLJS.Y8=function(a,b){var c=$CLJS.X8(a);return(0,$CLJS.PV)(a,0,function(d){var e=$CLJS.bt.g(vva,c);d=$CLJS.Ne.j($CLJS.Xj,d,e);d=$CLJS.wk.l($CLJS.H([d,$CLJS.Cl(b,c)]));$CLJS.bt.g(c,$CLJS.Wg($CLJS.Ng(b)));$CLJS.EV(a,0);return d})};$CLJS.Z8=function(a){return $CLJS.Be($CLJS.Cl($CLJS.EV(a,0),$CLJS.X8(a)))};
$CLJS.$8=function(a){return $CLJS.iP.h($CLJS.EV(a,0))};$CLJS.a9=function(a){return $CLJS.FP.h($CLJS.EV(a,0))};uva=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);var qva,vva;qva=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.b9=function(){function a(d,e){d=$CLJS.Be(rva(d));var f=$CLJS.Be($CLJS.Wg($CLJS.Ng(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.bt.g(d,f);d=$CLJS.bt.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.z(d);k=$CLJS.z(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.nD.h(f),$CLJS.e1.g($CLJS.xA,d))?$CLJS.e1.g($CLJS.xA,k):$CLJS.nD.h(f);f=$CLJS.R.l($CLJS.Xj.l(f,$CLJS.sL,$CLJS.H([$CLJS.bQ,$CLJS.TO])),$CLJS.nD,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Xj.g(e,d),k,f)}else e=$CLJS.wk.l($CLJS.H([$CLJS.VV($CLJS.Oe(d),
e),pva($CLJS.T,$CLJS.cf.g(sva,k))]));e=$CLJS.hs(e,tva)}else e=$CLJS.N;return e}function b(d){return $CLJS.b9.g?$CLJS.b9.g(d,null):$CLJS.b9.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();vva=new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.lN,null],null),null);
$CLJS.Y(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.sD],null)],null));
$CLJS.c9=function(){function a(d,e,f,k){var l=$CLJS.b9.h(e);return $CLJS.Y8($CLJS.a1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.fj,$CLJS.aV,$CLJS.nU,f,$CLJS.FP,l,$CLJS.iP,e],null)],null)),k)}function b(d,e){return $CLJS.c9.v?$CLJS.c9.v(d,e,null,null):$CLJS.c9.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.d9=function(){function a(d,e,f){$CLJS.EV(d,0);return $CLJS.Y8($CLJS.a1.g(e,$CLJS.sK.h(d)),f)}function b(d,e){return $CLJS.d9.j?$CLJS.d9.j(d,e,null):$CLJS.d9.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Z0.m(null,$CLJS.aV,function(a){return $CLJS.R0($CLJS.X8(a),$CLJS.Wg($CLJS.Ng($CLJS.Z8(a))))&&!$CLJS.Hz($CLJS.$8(a))});
$CLJS.$0.m(null,$CLJS.aV,function(a){return $CLJS.Le(function(b){b=$CLJS.O(b);var c=$CLJS.J.g(b,$CLJS.XM);(c=$CLJS.Sa(c))?b=c:(b=$CLJS.O(b),b=$CLJS.J.g(b,$CLJS.Oh),b="string"===typeof b||$CLJS.ud(b)?$CLJS.Be(b):null!=b);return b},$CLJS.Pg($CLJS.a9(a)))});