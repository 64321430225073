var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Dta,Eta,L7,Fta,Gta,Hta,Ita,Jta,Kta,Lta,M7,N7,O7;
Dta=function(a){return function(b){var c=$CLJS.Ve($CLJS.Sg);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Cd($CLJS.q(c),t))return l;c.nd(null,$CLJS.Yd.g(c.Lb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};Eta=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.fD(function(d){return $CLJS.V_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.E_,c,$CLJS.y_,!1],null))}),$CLJS.DM.h($CLJS.EV(a,b)))};L7=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.fD(function(d){return $CLJS.T_.v(a,b,d,c)}),$CLJS.DM.h($CLJS.EV(a,b)))};$CLJS.x3=function(a,b){return $CLJS.bb(function(c,d){return $CLJS.PV.l(c,d,$CLJS.R,$CLJS.H([$CLJS.o7,$CLJS.T_.j(c,d,$CLJS.EV(c,d))]))},a,$CLJS.qt(0,$CLJS.DV(a,b)))};
Fta=function(a,b){a=$CLJS.EV(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.fj),d=$CLJS.J.g(a,$CLJS.NU);b=$CLJS.o7.h(a);if($CLJS.n(b))return b;var e=$CLJS.nU.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.aV);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.S:null;switch(k){case "mbql.stage/native":return $CLJS.TY;case "mbql.stage/mbql":return $CLJS.MY;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Be(function(){return function m(l){return new $CLJS.ie(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A);C=$CLJS.wk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.uY,$CLJS.T.h(C),$CLJS.JY,$CLJS.T.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.OJ,f],null)]));x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);return $CLJS.ae($CLJS.wk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.uY,$CLJS.T.h(x),$CLJS.JY,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.OJ,f],null)])),m($CLJS.Hc(t)))}return null}},null,null)}($CLJS.BV.h(e))}())}return null};
Gta=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.zz($CLJS.uY,$CLJS.T)(x);x=$CLJS.i1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.OJ,$CLJS.dK,$CLJS.H([$CLJS.uY,S,$CLJS.JY,x]));A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.R.l(u,$CLJS.OJ,$CLJS.dK,$CLJS.H([$CLJS.uY,$CLJS.zz($CLJS.uY,$CLJS.T)(u),$CLJS.JY,function(){var v=$CLJS.i1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.O5.g(a,b))}())};
Hta=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.OJ,$CLJS.lK,$CLJS.H([$CLJS.uY,S,$CLJS.JY,x]));A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=
$CLJS.z(k);return $CLJS.ae($CLJS.R.l(u,$CLJS.OJ,$CLJS.lK,$CLJS.H([$CLJS.uY,$CLJS.T.h(u),$CLJS.JY,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.r4.g(a,b))}())};
Ita=function(a,b,c){var d=$CLJS.EV(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.UD);return $CLJS.Be(function(){return function l(k){return new $CLJS.ie(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.hd(t,K),X=$CLJS.I(S,0,null),U=function(){var oa=X;oa=oa instanceof $CLJS.M?oa.S:null;switch(oa){case "field":return $CLJS.RY;case "expression":return $CLJS.DY;default:throw Error(["No matching clause: ",
$CLJS.p.h(oa)].join(""));}}(),da=$CLJS.g_.j(a,b,S);$CLJS.pe(v,$CLJS.R.l(da,$CLJS.OJ,U,$CLJS.H([$CLJS.uY,$CLJS.L_.j(a,b,da),$CLJS.JY,function(){var oa=$CLJS.i1(a,da);return c.h?c.h(oa):c.call(null,oa)}()])));K+=1}else return!0}()?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}var x=$CLJS.z(m),A=$CLJS.I(x,0,null),C=function(){var K=A;K=K instanceof $CLJS.M?K.S:null;switch(K){case "field":return $CLJS.RY;case "expression":return $CLJS.DY;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),G=$CLJS.g_.j(a,b,x);return $CLJS.ae($CLJS.R.l(G,$CLJS.OJ,C,$CLJS.H([$CLJS.uY,$CLJS.L_.j(a,b,G),$CLJS.JY,function(){var K=$CLJS.i1(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Hc(m)))}return null}},null,null)}(e)}())}return null};Jta=function(a,b,c){return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.fD(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[Gta,Hta],null)))};
Kta=function(a,b,c){var d=$CLJS.G1(a,b);return $CLJS.n(d)?$CLJS.Be(function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);return function(){for(var A=0;;)if(A<t){var C=$CLJS.hd(m,A);var G=$CLJS.zz($CLJS.JY,$CLJS.uY)(C);G=$CLJS.n(G)?G:$CLJS.L_.j(a,b,C);C=$CLJS.Xj.l($CLJS.wk.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.OJ,$CLJS.vY,$CLJS.uY,G,$CLJS.JY,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.n1.h(C))?
$CLJS.Cl(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.n1],null)):null])),$CLJS.IV,$CLJS.H([$CLJS.rD]));u.add(C);A+=1}else return!0}()?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}var v=$CLJS.z(l),x=function(){var A=$CLJS.zz($CLJS.JY,$CLJS.uY)(v);return $CLJS.n(A)?A:$CLJS.L_.j(a,b,v)}();return $CLJS.ae($CLJS.Xj.l($CLJS.wk.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.OJ,$CLJS.vY,$CLJS.uY,x,$CLJS.JY,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.n1.h(v))?$CLJS.Cl(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.n1],null)):null])),$CLJS.IV,$CLJS.H([$CLJS.rD])),k($CLJS.Hc(l)))}return null}},null,null)}($CLJS.T_.j(a,d,$CLJS.EV(a,d)))}()):null};Lta=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.c_(a,c),$CLJS.n(c)?$CLJS.Be($CLJS.V_.v(a,b,c,d)):null):null};
M7=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t;var C=$CLJS.tA.h(x);var G=$CLJS.R.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.D1(G.call($CLJS.R,K,$CLJS.OJ,$CLJS.DY,$CLJS.H([$CLJS.uY,S,$CLJS.JY,x])),$CLJS.oi,$CLJS.n(C)?C:$CLJS.bj);A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),
f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=$CLJS.z(k);return $CLJS.ae(function(){var v=$CLJS.tA.h(u),x=$CLJS.R.l,A=u,C=$CLJS.T.h(u);var G=$CLJS.T.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.D1(x.call($CLJS.R,A,$CLJS.OJ,$CLJS.DY,$CLJS.H([$CLJS.uY,C,$CLJS.JY,G])),$CLJS.oi,$CLJS.n(v)?v:$CLJS.bj)}(),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.Fpa.g(a,b))}())};
N7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.E_);return $CLJS.gk.g(function(f){return $CLJS.Xj.l(f,$CLJS.cZ,$CLJS.H([$CLJS.IV,$CLJS.NV,$CLJS.aK]))},function(){var f=Kta(a,b,e);if($CLJS.n(f))return f;f=$CLJS.EV(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.eO),l=$CLJS.J.g(f,$CLJS.NU),m=$CLJS.n(k)?function(){var t=$CLJS.a_(a,k);return $CLJS.V_.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Lta(a,b,l,$CLJS.R.j(d,$CLJS.y_,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.ie(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.vd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var S=0;;)if(S<C){var X=$CLJS.hd(A,S),U=G,da=$CLJS.R,oa=da.l,Oa=X,rb=$CLJS.T.h(X);X=$CLJS.T.h(X);X=e.h?e.h(X):e.call(null,X);da=oa.call(da,Oa,$CLJS.OJ,$CLJS.TY,$CLJS.H([$CLJS.uY,rb,$CLJS.JY,X]));U.add(da);S+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var K=$CLJS.z(x);return $CLJS.ae($CLJS.R.l(K,$CLJS.OJ,$CLJS.TY,$CLJS.H([$CLJS.uY,$CLJS.T.h(K),
$CLJS.JY,function(){var S=$CLJS.T.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.BV.h($CLJS.nU.h(f)))}())};O7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.nra={};$CLJS.sE($CLJS.UU,O7);$CLJS.sE($CLJS.aV,O7);$CLJS.O0.m(null,$CLJS.UU,function(a){return $CLJS.N0(a,new $CLJS.h(null,2,[$CLJS.bF,$CLJS.Qe($CLJS.gk,$CLJS.O0),$CLJS.hV,$CLJS.Qe($CLJS.gk,$CLJS.O0)],null))});$CLJS.P_.m(null,O7,function(){throw $CLJS.Uh("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.U_.m(null,O7,function(a,b,c,d){var e=$CLJS.O(d);c=$CLJS.J.g(e,$CLJS.E_);d=$CLJS.J.g(e,$CLJS.y_);a=$CLJS.x3(a,b);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.E_),k=$CLJS.J.g(e,$CLJS.C_),l=$CLJS.J.g(e,$CLJS.I_);e=$CLJS.bf.l(N7(a,b,e),$CLJS.n(l)?M7(a,b,f):null,$CLJS.H([$CLJS.n(k)?Eta(a,b,f):null]));return $CLJS.yf($CLJS.bf.g(e,$CLJS.n(d)?$CLJS.j1(a,b,e,c):null))});
$CLJS.S_.m(null,O7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.E_);var e=Fta(a,b);if($CLJS.n(e))return e;a=$CLJS.x3(a,b);e=Jta(a,b,d);var f=Ita(a,b,d);return $CLJS.n(e)?$CLJS.Wf.g(e,f):$CLJS.n(f)?($CLJS.ch(f),$CLJS.Wf.j($CLJS.xf,Dta(function(k){return $CLJS.Xj.l(k,$CLJS.XY,$CLJS.H([$CLJS.OJ,$CLJS.A0,$CLJS.JY]))}),$CLJS.bf.g(f,L7(a,b,c)))):$CLJS.bf.l(N7(a,b,new $CLJS.h(null,2,[$CLJS.y_,!1,$CLJS.E_,d],null)),M7(a,b,d),$CLJS.H([L7(a,b,c)]))});$CLJS.J_.m(null,$CLJS.aV,function(){return $CLJS.MD("Native query")});
var P7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eO,$CLJS.NU,$CLJS.DM],null),Q7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bF,$CLJS.aF,$CLJS.hV,$CLJS.NQ,$CLJS.jR],null);
$CLJS.J_.m(null,$CLJS.UU,function(a,b,c,d){var e=$CLJS.x3(a,b);a=$CLJS.Be(function(){var f=$CLJS.Wf.j($CLJS.N,$CLJS.ek.g($CLJS.sB,$CLJS.cf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.Yoa.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[P7,Q7],null)),k=$CLJS.is(" + ",$CLJS.fk.g($CLJS.Hz,$CLJS.cf.g(f,P7)));f=$CLJS.cf.g(f,Q7);return $CLJS.is(", ",$CLJS.fk.g($CLJS.Hz,$CLJS.ae(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.G1(e,b);return $CLJS.n(a)?$CLJS.i_.v(e,a,$CLJS.EV(e,a),d):null});