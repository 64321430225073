var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.tools.reader.js");require("./cljs.tools.reader.reader_types.js");require("./clojure.string.js");require("./cljs.tools.reader.impl.inspect.js");
'use strict';var fz,gz,Nfa;$CLJS.ez=function(a){this.gd=a;this.J=null;this.C=2153775104;this.I=2048};fz=function(a){a=parseInt(a,10);return $CLJS.Sa(isNaN(a))?a:null};gz=function(a,b,c,d){if(!(a<=b&&b<=c))throw Error([$CLJS.p.h(d)," Failed:  ",$CLJS.p.h(a),"\x3c\x3d",$CLJS.p.h(b),"\x3c\x3d",$CLJS.p.h(c)].join(""));return b};
Nfa=function(a){var b=$CLJS.dh(Lfa,a);$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null),d=$CLJS.I(b,2,null),e=$CLJS.I(b,3,null),f=$CLJS.I(b,4,null),k=$CLJS.I(b,5,null),l=$CLJS.I(b,6,null),m=$CLJS.I(b,7,null),t=$CLJS.I(b,8,null),u=$CLJS.I(b,9,null),v=$CLJS.I(b,10,null);if($CLJS.Sa(b))throw Error(["Unrecognized date/time syntax: ",$CLJS.p.h(a)].join(""));a=fz(c);b=function(){var K=fz(d);return $CLJS.n(K)?K:1}();c=function(){var K=fz(e);return $CLJS.n(K)?K:1}();var x=function(){var K=fz(f);return $CLJS.n(K)?
K:0}(),A=function(){var K=fz(k);return $CLJS.n(K)?K:0}(),C=function(){var K=fz(l);return $CLJS.n(K)?K:0}(),G=function(){a:if($CLJS.E.g(3,$CLJS.D(m)))var K=m;else if(3<$CLJS.D(m))K=m.substring(0,3);else for(K=new $CLJS.Ca(m);;)if(3>K.getLength())K=K.append("0");else{K=K.toString();break a}K=fz(K);return $CLJS.n(K)?K:0}();t=($CLJS.E.g(t,"-")?-1:1)*(60*function(){var K=fz(u);return $CLJS.n(K)?K:0}()+function(){var K=fz(v);return $CLJS.n(K)?K:0}());return new $CLJS.P(null,8,5,$CLJS.Q,[a,gz(1,b,12,"timestamp month field must be in range 1..12"),
gz(1,c,Mfa(b,0===(a%4+4)%4&&(0!==(a%100+100)%100||0===(a%400+400)%400)),"timestamp day field must be in range 1..last day in month"),gz(0,x,23,"timestamp hour field must be in range 0..23"),gz(0,A,59,"timestamp minute field must be in range 0..59"),gz(0,C,$CLJS.E.g(A,59)?60:59,"timestamp second field must be in range 0..60"),gz(0,G,999,"timestamp millisecond field must be in range 0..999"),t],null)};$CLJS.g=$CLJS.ez.prototype;$CLJS.g.eg=$CLJS.yc;$CLJS.g.toString=function(){return this.gd};
$CLJS.g.equiv=function(a){return this.V(null,a)};$CLJS.g.V=function(a,b){return(a=null!=b?$CLJS.yc===b.eg?!0:!1:!1)?this.gd===b.gd:a};$CLJS.g.da=function(a,b){return $CLJS.Yb(b,['#uuid "',$CLJS.p.h(this.gd),'"'].join(""))};$CLJS.g.fa=function(){null==this.J&&(this.J=$CLJS.zc(this.gd));return this.J};$CLJS.g.nc=function(a,b){if(b instanceof $CLJS.ez)return $CLJS.Ba(this.gd,b.gd);throw Error(["Cannot compare ",$CLJS.p.h(this)," to ",$CLJS.p.h(b)].join(""));};
$CLJS.hz=new $CLJS.M(null,"pattern","pattern",242135423);$CLJS.iz=new $CLJS.M(null,"replacement","replacement",-1836238839);var Mfa,Lfa;Mfa=function(){var a=new $CLJS.P(null,13,5,$CLJS.Q,[null,31,28,31,30,31,30,31,31,30,31,30,31],null),b=new $CLJS.P(null,13,5,$CLJS.Q,[null,31,29,31,30,31,30,31,31,30,31,30,31],null);return function(c,d){return $CLJS.J.g($CLJS.n(d)?b:a,c)}}();Lfa=/(\d\d\d\d)(?:-(\d\d)(?:-(\d\d)(?:[T](\d\d)(?::(\d\d)(?::(\d\d)(?:[.](\d+))?)?)?)?)?)?(?:[Z]|([-+])(\d\d):(\d\d))?/;
$CLJS.jz=$CLJS.Se($CLJS.wk.l($CLJS.H([new $CLJS.h(null,4,[new $CLJS.r(null,"inst","inst",-2008473268,null),function(a){if("string"===typeof a){var b=Nfa(a);if($CLJS.n(b)){a=$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null),d=$CLJS.I(b,2,null),e=$CLJS.I(b,3,null),f=$CLJS.I(b,4,null),k=$CLJS.I(b,5,null),l=$CLJS.I(b,6,null);b=$CLJS.I(b,7,null);b=new Date(Date.UTC(a,c-1,d,e,f,k,l)-6E4*b)}else throw Error(["Unrecognized date/time syntax: ",$CLJS.p.h(a)].join(""));return b}throw Error("Instance literal expects a string for its timestamp.");
},$CLJS.Jaa,function(a){if("string"===typeof a)return new $CLJS.ez(a.toLowerCase());throw Error("UUID literal expects a string as its representation.");},new $CLJS.r(null,"queue","queue",-1198599890,null),function(a){if($CLJS.ud(a))return $CLJS.Wf.g($CLJS.nk,a);throw Error("Queue literal expects a vector for its elements.");},new $CLJS.r(null,"js","js",-886355190,null),function(a){if($CLJS.ud(a)){var b=[];a=$CLJS.y(a);for(var c=null,d=0,e=0;;)if(e<d){var f=c.X(null,e);b.push(f);e+=1}else if(a=$CLJS.y(a))c=
a,$CLJS.vd(c)?(a=$CLJS.ic(c),e=$CLJS.jc(c),c=a,d=$CLJS.D(a),a=e):(a=$CLJS.z(c),b.push(a),a=$CLJS.B(c),c=null,d=0),e=0;else break;return b}if($CLJS.sd(a)){b={};a=$CLJS.y(a);c=null;for(e=d=0;;)if(e<d){var k=c.X(null,e);f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);f=$CLJS.Xg(f);b[f]=k;e+=1}else if(a=$CLJS.y(a))$CLJS.vd(a)?(d=$CLJS.ic(a),a=$CLJS.jc(a),c=d,d=$CLJS.D(d)):(d=$CLJS.z(a),c=$CLJS.I(d,0,null),d=$CLJS.I(d,1,null),c=$CLJS.Xg(c),b[c]=d,a=$CLJS.B(a),c=null,d=0),e=0;else break;return b}throw Error("JS literal expects a vector or map containing only string or unqualified keyword keys");
}],null),$CLJS.N])));