var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var QI;$CLJS.OI=function(a){return $CLJS.n($CLJS.rB($CLJS.rd,$CLJS.qd,$CLJS.Yk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.PI=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,U){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Oa=oa,rb=$CLJS.z(Oa);if(oa=$CLJS.y(function(Ra,Wa,eb,Ka,lb,Ja,bc,Kd){return function Rh(gd){return new $CLJS.ie(null,function(pz,Il,Un,xr,R_,Lv){return function(){for(;;){var Mv=
$CLJS.y(gd);if(Mv){if($CLJS.vd(Mv)){var Nv=$CLJS.ic(Mv),qz=$CLJS.D(Nv),Fk=$CLJS.le(qz);a:for(var yr=0;;)if(yr<qz){var ht=$CLJS.hd(Nv,yr);ht=$CLJS.E.g(Lv,$CLJS.aE)||$CLJS.E.g(Un,$CLJS.aE)||$CLJS.CE(Lv,ht)&&$CLJS.CE(Un,ht);Fk.add(ht);yr+=1}else{Nv=!0;break a}return Nv?$CLJS.oe($CLJS.qe(Fk),Rh($CLJS.jc(Mv))):$CLJS.oe($CLJS.qe(Fk),null)}Fk=$CLJS.z(Mv);return $CLJS.ae($CLJS.E.g(Lv,$CLJS.aE)||$CLJS.E.g(Un,$CLJS.aE)||$CLJS.CE(Lv,Fk)&&$CLJS.CE(Un,Fk),Rh($CLJS.Hc(Mv)))}return null}}}(Ra,Wa,eb,Ka,lb,Ja,bc,
Kd),null,null)}}(da,K,rb,Oa,oa,S,X,U)($CLJS.mE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.OI($CLJS.PD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.OI($CLJS.PD(a)))}())};
QI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.Ne.j($CLJS.yE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);return $CLJS.Le($CLJS.il,
$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.PI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.RI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.SI=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.TI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var UI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lr,$CLJS.er],null)),VI=null,WI=0,XI=0;;)if(XI<WI){var Lia=VI.X(null,XI);$CLJS.BE(Lia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null)],null)],null)]));XI+=1}else{var YI=$CLJS.y(UI);if(YI){var ZI=YI;if($CLJS.vd(ZI)){var $I=$CLJS.ic(ZI),Mia=$CLJS.jc(ZI),
Nia=$I,Oia=$CLJS.D($I);UI=Mia;VI=Nia;WI=Oia}else{var Pia=$CLJS.z(ZI);$CLJS.BE(Pia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null)],null)],null)]));UI=$CLJS.B(ZI);VI=null;WI=0}XI=0}else break}$CLJS.zE($CLJS.gr,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)]));
for(var aJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.CF],null)),bJ=null,cJ=0,dJ=0;;)if(dJ<cJ){var Qia=bJ.X(null,dJ);$CLJS.BE(Qia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)],null)],null)],null)]));dJ+=1}else{var eJ=$CLJS.y(aJ);if(eJ){var fJ=eJ;if($CLJS.vd(fJ)){var gJ=$CLJS.ic(fJ),Ria=$CLJS.jc(fJ),
Sia=gJ,Tia=$CLJS.D(gJ);aJ=Ria;bJ=Sia;cJ=Tia}else{var Uia=$CLJS.z(fJ);$CLJS.BE(Uia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)],null)],null)],null)]));aJ=$CLJS.B(fJ);bJ=null;cJ=0}dJ=0}else break}
for(var hJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Eq,$CLJS.Gq,$CLJS.Aq,$CLJS.Cq],null)),iJ=null,jJ=0,kJ=0;;)if(kJ<jJ){var Via=iJ.X(null,kJ);$CLJS.xE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Via,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)]));kJ+=1}else{var lJ=$CLJS.y(hJ);if(lJ){var mJ=lJ;if($CLJS.vd(mJ)){var nJ=$CLJS.ic(mJ),Wia=$CLJS.jc(mJ),Xia=nJ,Yia=$CLJS.D(nJ);hJ=Wia;iJ=Xia;jJ=Yia}else{var Zia=
$CLJS.z(mJ);$CLJS.xE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Zia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)]));hJ=$CLJS.B(mJ);iJ=null;jJ=0}kJ=0}else break}
$CLJS.xE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.OE,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)]));
$CLJS.xE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.UE,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)]));
for(var oJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NE,$CLJS.ZE],null)),pJ=null,qJ=0,rJ=0;;)if(rJ<qJ){var $ia=pJ.X(null,rJ);$CLJS.zE($ia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));rJ+=1}else{var sJ=$CLJS.y(oJ);if(sJ){var tJ=sJ;if($CLJS.vd(tJ)){var uJ=$CLJS.ic(tJ),aja=$CLJS.jc(tJ),bja=uJ,cja=$CLJS.D(uJ);oJ=aja;pJ=bja;qJ=cja}else{var dja=$CLJS.z(tJ);$CLJS.zE(dja,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));oJ=$CLJS.B(tJ);
pJ=null;qJ=0}rJ=0}else break}
for(var vJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PE,$CLJS.WE],null)),wJ=null,xJ=0,yJ=0;;)if(yJ<xJ){var eja=wJ.X(null,yJ);$CLJS.zE(eja,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)]));yJ+=1}else{var zJ=$CLJS.y(vJ);if(zJ){var AJ=zJ;if($CLJS.vd(AJ)){var BJ=$CLJS.ic(AJ),fja=$CLJS.jc(AJ),gja=BJ,hja=$CLJS.D(BJ);vJ=fja;wJ=gja;xJ=hja}else{var ija=$CLJS.z(AJ);$CLJS.zE(ija,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)]));vJ=$CLJS.B(AJ);
wJ=null;xJ=0}yJ=0}else break}
for(var CJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null),DJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dF,$CLJS.xF,$CLJS.lF,$CLJS.eF],null)),EJ=null,FJ=0,GJ=0;;)if(GJ<FJ){var HJ=EJ.X(null,GJ);$CLJS.LF.v(HJ,$CLJS.$r,$CLJS.mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,HJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.yD,CJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.iE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iE],null)],null));GJ+=1}else{var IJ=$CLJS.y(DJ);if(IJ){var JJ=IJ;if($CLJS.vd(JJ)){var KJ=$CLJS.ic(JJ),jja=$CLJS.jc(JJ),kja=KJ,lja=$CLJS.D(KJ);DJ=jja;EJ=kja;FJ=lja}else{var LJ=$CLJS.z(JJ);$CLJS.LF.v(LJ,$CLJS.$r,$CLJS.mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,LJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.yD,CJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.iE],null)],null));DJ=$CLJS.B(JJ);EJ=null;FJ=0}GJ=0}else break}
$CLJS.LF.v($CLJS.eB,$CLJS.$r,$CLJS.mj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.eB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.yD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ik,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Qu,$CLJS.Vw,$CLJS.TE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dD],null)],null));$CLJS.LF.v($CLJS.KE,$CLJS.$r,$CLJS.mj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.KE],null),$CLJS.yD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.jD,$CLJS.sD],null)],null));
$CLJS.Y($CLJS.RI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.SI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.br,$CLJS.Hq,$CLJS.CF,$CLJS.UE,$CLJS.OE,$CLJS.Eq,$CLJS.Aq,$CLJS.Gq,$CLJS.Cq,$CLJS.NE,$CLJS.ZE,$CLJS.PE,$CLJS.WE,$CLJS.lF,$CLJS.eF,$CLJS.dF,$CLJS.xF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,$CLJS.Fi],null)],null));