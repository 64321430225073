var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var SH,UH,WH,eI,hI,mI;$CLJS.RH=new $CLJS.M(null,"get-month","get-month",-369374731);SH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.TH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);UH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.VH=new $CLJS.M(null,"iso","iso",-1366207543);WH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.XH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.YH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.ZH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.$H=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.aI=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.bI=new $CLJS.M(null,"us","us",746429226);$CLJS.cI=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.dI=new $CLJS.M(null,"now","now",-1650525531);
eI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.fI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.gI=new $CLJS.M(null,"get-day","get-day",127568857);hI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.iI=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.jI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.kI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.lI=new $CLJS.M(null,"get-hour","get-hour",622714059);mI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.nI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.oI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.pI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.qI=new $CLJS.M(null,"target","target",253001721);$CLJS.zE($CLJS.$A,$CLJS.H([$CLJS.$r,$CLJS.bC,$CLJS.qr,$CLJS.dD]));$CLJS.OD.m(null,$CLJS.$H,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.PD(a);return $CLJS.qd(a)?(a=$CLJS.ct.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ji,null,$CLJS.dj,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.sE($CLJS.$H,$CLJS.eE);
for(var rI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cB,$CLJS.fB],null)),sI=null,tI=0,uI=0;;)if(uI<tI){var vI=sI.X(null,uI);$CLJS.zE(vI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null),$CLJS.qr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dD],null)]));$CLJS.sE(vI,$CLJS.$H);uI+=1}else{var wI=$CLJS.y(rI);if(wI){var xI=wI;if($CLJS.vd(xI)){var yI=$CLJS.ic(xI),yia=$CLJS.jc(xI),zia=yI,Aia=$CLJS.D(yI);rI=yia;sI=zia;tI=Aia}else{var zI=$CLJS.z(xI);$CLJS.zE(zI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.XD],null),$CLJS.qr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dD],null)]));$CLJS.sE(zI,$CLJS.$H);rI=$CLJS.B(xI);sI=null;tI=0}uI=0}else break}
for(var AI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YH,$CLJS.RH,$CLJS.gI,$CLJS.lI,$CLJS.iI,$CLJS.XH,$CLJS.nI],null)),BI=null,CI=0,DI=0;;)if(DI<CI){var Bia=BI.X(null,DI);$CLJS.zE(Bia,$CLJS.H([$CLJS.$r,$CLJS.Yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)]));DI+=1}else{var EI=$CLJS.y(AI);if(EI){var FI=EI;if($CLJS.vd(FI)){var GI=$CLJS.ic(FI),Cia=$CLJS.jc(FI),Dia=GI,Eia=$CLJS.D(GI);AI=Cia;BI=Dia;CI=Eia}else{var Fia=$CLJS.z(FI);$CLJS.zE(Fia,$CLJS.H([$CLJS.$r,
$CLJS.Yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)]));AI=$CLJS.B(FI);BI=null;CI=0}DI=0}else break}$CLJS.zE($CLJS.ZA,$CLJS.H([$CLJS.$r,$CLJS.Yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$F],null)]));
for(var HI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.nI,null,$CLJS.iI,null,$CLJS.lI,null,$CLJS.jI,null,$CLJS.XH,null,$CLJS.RH,null,$CLJS.YH,null,$CLJS.gI,null],null),null)),II=null,JI=0,KI=0;;)if(KI<JI){var Gia=II.X(null,KI);$CLJS.zE(Gia,$CLJS.H([$CLJS.$r,$CLJS.Yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)]));KI+=1}else{var LI=$CLJS.y(HI);if(LI){var MI=LI;if($CLJS.vd(MI)){var NI=$CLJS.ic(MI),Hia=$CLJS.jc(MI),Iia=NI,Jia=$CLJS.D(NI);
HI=Hia;II=Iia;JI=Jia}else{var Kia=$CLJS.z(MI);$CLJS.zE(Kia,$CLJS.H([$CLJS.$r,$CLJS.Yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)]));HI=$CLJS.B(MI);II=null;JI=0}KI=0}else break}$CLJS.Y(mI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.VH,$CLJS.bI,$CLJS.fI],null));
$CLJS.BE($CLJS.bB,$CLJS.H([$CLJS.$r,$CLJS.Yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mI],null)],null)],null)],null)]));
$CLJS.Y($CLJS.kI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.sD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,2,[$CLJS.us,"valid timezone ID",$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.Cz($CLJS.Dd,$CLJS.QH.tz.names())),$CLJS.HH],null)],null));
$CLJS.BE($CLJS.TH,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yy,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kI],null)],null)],null)],
null)]));$CLJS.sE($CLJS.TH,$CLJS.$H);$CLJS.zE($CLJS.dI,$CLJS.H([$CLJS.$r,$CLJS.Ck]));$CLJS.Y(WH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.Dz(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ji,$CLJS.dj],null))}],null)],null));
$CLJS.Y(SH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WH],null)],null)],null)],null));
$CLJS.LF.g($CLJS.ZH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.ZH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bG],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Qu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XF],null)],null)],null)],null)],null)],null));
$CLJS.OD.m(null,$CLJS.ZH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Qu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.dj:$CLJS.n($CLJS.rE($CLJS.bG,a))?$CLJS.ji:$CLJS.dj:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.BH,b))?$CLJS.ji:$CLJS.n($CLJS.dh($CLJS.CH,b))?$CLJS.ji:null:null;if($CLJS.n(a))return a;b=$CLJS.PD(b);b=$CLJS.qd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.Dz(c,$CLJS.tj)}),b):b;return $CLJS.qd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.Y(hI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Qu],null),$CLJS.qr],null));
$CLJS.BE($CLJS.aB,$CLJS.H([$CLJS.$r,$CLJS.dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dD],null)],null)],null)],null)]));
$CLJS.zE($CLJS.Fx,$CLJS.H([$CLJS.$r,$CLJS.Nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aG],null)]));$CLJS.Y(UH,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.br,$CLJS.ZF,$CLJS.ki,$CLJS.Dj,$CLJS.aI,$CLJS.oI,$CLJS.pI,$CLJS.ij,$CLJS.pi,$CLJS.fi,$CLJS.Pi,$CLJS.WF],null));$CLJS.Y(eI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.VH,$CLJS.bI,$CLJS.fI],null));
$CLJS.BE($CLJS.dB,$CLJS.H([$CLJS.$r,$CLJS.Yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eI],null)],null)],
null)],null)]));