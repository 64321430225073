var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var k7,l7,m7,pta,qta,rta,p7,q7,s7,t7,sta,tta,uta,v7,w7,x7,vta,wta;k7=function(a){var b=new $CLJS.h(null,3,[$CLJS.AD,$CLJS.p.h($CLJS.LD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.oi,$CLJS.zz($CLJS.oi,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.rD,$CLJS.T)(a)],null)};l7=function(a){return $CLJS.R.j(a,$CLJS.fj,$CLJS.A4)};m7=function(a,b){return $CLJS.E.g($CLJS.mD.h(a),$CLJS.Cl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FD,$CLJS.lD,$CLJS.ED],null)))};
pta=function(a){return $CLJS.N0(a,new $CLJS.h(null,1,[$CLJS.FD,$CLJS.mh],null))};
qta=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.uG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JY,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.gD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.gk.g($CLJS.JY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
rta=function(a,b,c){if($CLJS.n(n7))return null;var d=n7;n7=!0;try{var e=$CLJS.G1(a,b),f=$CLJS.n(e)?$CLJS.EV(a,e):$CLJS.EV(a,b),k=function(){var m=$CLJS.o7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nU,$CLJS.BV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.NU.h(f);if($CLJS.n(u))return u;u=$CLJS.eO.h(f);if($CLJS.n(u))return u;u=$CLJS.ZO.h(f);return $CLJS.n(u)?u:$CLJS.UD.h(f)}())?$CLJS.V_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.gD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?qta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.g3($CLJS.Xj.l(l,$CLJS.Hi,$CLJS.H([$CLJS.bK,$CLJS.NV,$CLJS.IV])),null),$CLJS.T,function(){var m=$CLJS.JY.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.OJ,$CLJS.vY):l:null}finally{n7=d}};
p7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.tO);var f=$CLJS.I(c,2,null);c=$CLJS.wk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.oi,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.oi,k],null):null}(),function(){var k=$CLJS.tQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.NV,k],null):null}(),function(){var k=$CLJS.sF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.IV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.M1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.fj,$CLJS.NJ,$CLJS.T,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.fj,$CLJS.NJ,$CLJS.T,$CLJS.p.h(f)],null):function(){var k=rta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.fj,$CLJS.NJ,$CLJS.T,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.g3(c,d):c};
q7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.IV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.rG,b):b)?$CLJS.Yi:$CLJS.zz($CLJS.oi,$CLJS.tA)(a)};
$CLJS.r7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.AD),l=$CLJS.J.g(f,$CLJS.tA),m=$CLJS.J.g(f,$CLJS.tQ),t=$CLJS.J.g(f,$CLJS.oi);e=$CLJS.J.g(f,$CLJS.tO);var u=$CLJS.J.g(f,$CLJS.EM),v=$CLJS.J.g(f,$CLJS.sF),x=$CLJS.wk.l;k=new $CLJS.h(null,2,[$CLJS.fj,$CLJS.NJ,$CLJS.A0,k],null);f=$CLJS.nD.h(f);a=$CLJS.n(f)?f:$CLJS.i_.j(a,b,d);c=x.call($CLJS.wk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.nD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.oi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.IV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.NV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.aK,u):m;return $CLJS.n(e)?$CLJS.g3(u,e):u};s7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.Bk.h(d),c)},a))?$CLJS.gk.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.Xj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.Bk.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
t7=function(a){var b=$CLJS.n($CLJS.n1.h(a))?null:function(){var e=$CLJS.OJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.MY,null,$CLJS.TY,null,$CLJS.vY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.wk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.AD,$CLJS.p.h($CLJS.LD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.oi,q7(a)],null),function(){var e=function(){var f=$CLJS.Sa(b);return f?(f=$CLJS.Sa($CLJS.aK.h(a)))?(f=$CLJS.dk.g($CLJS.FY,$CLJS.OJ.h(a)))?$CLJS.XY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.tO,e],null):null}(),function(){var e=$CLJS.g1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.tO,e],null):null}(),function(){var e=$CLJS.IV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.sF,e],null):null}(),function(){var e=$CLJS.MV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.MV,e],null):null}(),function(){var e=$CLJS.NV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.tQ,e],null):null}(),function(){var e=$CLJS.aK.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.EM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.JY,$CLJS.T):$CLJS.zz($CLJS.Hi,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,c,d],null)};sta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.OJ.h(c),$CLJS.DY)},$CLJS.V_.v(a,b,$CLJS.EV(a,b),new $CLJS.h(null,3,[$CLJS.C_,!1,$CLJS.I_,!0,$CLJS.y_,!1],null)))};tta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);uta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.u7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);v7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);w7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.o7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);x7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
vta=new $CLJS.M(null,"earliest","earliest",-1928154382);wta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.O0.m(null,$CLJS.mF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.N0(c,new $CLJS.h(null,2,[$CLJS.sF,$CLJS.mh,$CLJS.tQ,pta],null)),a],null)});
var n7=!1,y7=function y7(a,b){var d=$CLJS.X0(a,w7.h(b));a=$CLJS.n(w7.h(d))?y7.g?y7.g(a,d):y7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T),f=$CLJS.J.g(a,$CLJS.nD);return $CLJS.hk.j($CLJS.R.j($CLJS.hk.j($CLJS.R.j(b,tta,$CLJS.T.h(b)),$CLJS.T,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),x7,$CLJS.nD.h(b)),$CLJS.nD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.N_.m(null,$CLJS.NJ,function(a,b,c){return q7(c)});
$CLJS.N_.m(null,$CLJS.mF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.sF);$CLJS.I(c,2,null);c=p7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.IV,d):c;return $CLJS.O_.j(a,b,c)});$CLJS.P_.m(null,$CLJS.NJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.P_.m(null,$CLJS.mF,function(a,b,c){var d=p7(a,b,c);b=$CLJS.r7(a,b,d,c);return $CLJS.n(w7.h(b))?y7(a,b):b});
$CLJS.J_.m(null,$CLJS.NJ,function(a,b,c,d){function e(U){var da=$CLJS.f5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",U,da):$CLJS.ha.call(null,"%s: %s",U,da)}function f(U){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",U,da):$CLJS.ha.call(null,"%s: %s",U,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.NV),m=$CLJS.J.g(k,$CLJS.aK),t=$CLJS.J.g(k,$CLJS.bK),u=$CLJS.J.g(k,$CLJS.Bk),v=$CLJS.J.g(k,$CLJS.XY),x=$CLJS.J.g(k,x7),A=$CLJS.J.g(k,$CLJS.T),C=$CLJS.J.g(k,$CLJS.F5),G=$CLJS.J.g(k,
$CLJS.nD),K=$CLJS.J.g(k,w7),S=$CLJS.e1.g($CLJS.xA,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var U=$CLJS.O(k);var da=$CLJS.J.g(U,$CLJS.nD);U=$CLJS.J.g(U,w7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(U)){U=$CLJS.X0(a,U);var oa=$CLJS.O(U);U=$CLJS.J.g(oa,$CLJS.nD);oa=$CLJS.J.g(oa,w7);da=$CLJS.Yd.g(da,U);U=oa}else break a}da=$CLJS.Le($CLJS.Ta,da)?$CLJS.is(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.j_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var U=$CLJS.X0(a,m);$CLJS.n(U)?U=$CLJS.c3($CLJS.nD.h($CLJS.l_.j(a,b,U))):(U=$CLJS.f1(a,t),U=$CLJS.i_.v(a,b,U,d))}else U=null;return $CLJS.n(U)?U:$CLJS.n(v)?v:$CLJS.g1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var U=$CLJS.dk.g(d,$CLJS.j_);return U?C:U}())?c:$CLJS.n($CLJS.n(u)?$CLJS.dk.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.dk.g(c,e(S)):l)?e(c):c});
$CLJS.J_.m(null,$CLJS.mF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.tQ);var k=$CLJS.J.g(f,$CLJS.tO),l=$CLJS.J.g(f,$CLJS.sF);f=$CLJS.J.g(f,$CLJS.EM);$CLJS.I(c,2,null);c=p7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.XY,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.Bk,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.NV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.aK,f):e;return $CLJS.n(e)?$CLJS.i_.v(a,b,e,d):$CLJS.MD("[Unknown Field]")});
$CLJS.K_.m(null,$CLJS.NJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.K_.m(null,$CLJS.mF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=p7(a,b,c);return $CLJS.n(c)?$CLJS.L_.j(a,b,c):"unknown_field"});
$CLJS.Q_.m(null,$CLJS.NJ,function(a,b,c){return $CLJS.wk.l($CLJS.H([function(){var d=$CLJS.nH($CLJS.Q_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.i_.j(a,b,c),$CLJS.k_,$CLJS.i_.v(a,b,c,$CLJS.j_)],null),$CLJS.E.g($CLJS.OJ.h(c),$CLJS.MY)?function(){var d=$CLJS.WY.h(c);return $CLJS.n(d)?(d=$CLJS.c_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Ij,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.nD,$CLJS.T.h(d)],null)],null):null):null}():null]))});
$CLJS.r0.m(null,$CLJS.mF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.sF.h(b)});$CLJS.r0.m(null,$CLJS.NJ,function(a){return $CLJS.IV.h(a)});
$CLJS.q0.m(null,$CLJS.mF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.rG,b),e=$CLJS.rB($CLJS.MV,$CLJS.oi,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.sF,b,$CLJS.H([$CLJS.oi,d?$CLJS.Yi:e,$CLJS.MV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,c,a],null)}b=$CLJS.MV.h(c);c=$CLJS.n(b)?$CLJS.Xj.g($CLJS.R.j(c,$CLJS.oi,b),$CLJS.MV):c;c=$CLJS.Xj.g(c,$CLJS.sF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,c,a],null)});
$CLJS.q0.m(null,$CLJS.NJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.IV,b,$CLJS.H([$CLJS.MV,$CLJS.rB($CLJS.MV,$CLJS.oi,$CLJS.tA)(a)])):$CLJS.Xj.l(a,$CLJS.IV,$CLJS.H([$CLJS.MV]))});$CLJS.t0.m(null,$CLJS.mF,function(a,b,c){return $CLJS.u0.j(a,b,p7(a,b,c))});
$CLJS.t0.m(null,$CLJS.NJ,function(a,b,c){if($CLJS.dk.g($CLJS.OJ.h(c),$CLJS.DY)){a=$CLJS.zz($CLJS.oi,$CLJS.tA)(c);b=null==c?null:$CLJS.SM.h(c);if(null==b)var d=null;else try{var e=$CLJS.dj.h($CLJS.Ri.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,vta),l=$CLJS.J.g(f,wta),m=$CLJS.lpa.l($CLJS.H([$CLJS.o0.h(k),$CLJS.o0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Bq.g?$CLJS.Bq.g(1,m):$CLJS.Bq.call(null,1,m))?$CLJS.Dk:$CLJS.n($CLJS.Bq.g?$CLJS.Bq.g(31,m):$CLJS.Bq.call(null,31,m))?$CLJS.Lk:$CLJS.n($CLJS.Bq.g?
$CLJS.Bq.g(365,m):$CLJS.Bq.call(null,365,m))?$CLJS.Ti:$CLJS.Zi}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.dj)?$CLJS.wpa:$CLJS.Dz(a,$CLJS.ji)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.Nj)?$CLJS.upa:$CLJS.xf;d=$CLJS.n(d)?s7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.IV.h(c))?s7(d,$CLJS.q_,$CLJS.IV.h(c)):d}return $CLJS.xf});
$CLJS.c5.m(null,$CLJS.mF,function(a){var b=null==a?null:$CLJS.ID(a);b=null==b?null:$CLJS.tQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.fj,$CLJS.h5,$CLJS.H([$CLJS.g5,function(c,d){return p7(c,d,a)}]))});$CLJS.c5.m(null,$CLJS.NJ,function(a){var b=null==a?null:$CLJS.NV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.fj,$CLJS.h5,$CLJS.H([$CLJS.g5,$CLJS.Pe(a)]))});$CLJS.i5.m(null,$CLJS.mF,function(a,b){return $CLJS.JD(a,$CLJS.HD,$CLJS.H([$CLJS.tQ,b]))});
$CLJS.i5.m(null,$CLJS.NJ,function(a,b){return $CLJS.HD(a,$CLJS.NV,b)});$CLJS.j5.m(null,$CLJS.mF,function(a,b,c){return $CLJS.k5.j(a,b,p7(a,b,c))});
$CLJS.j5.m(null,$CLJS.NJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.oi);var d=$CLJS.J.g(b,$CLJS.SM),e=$CLJS.J.g(b,$CLJS.sj);if($CLJS.dk.g($CLJS.OJ.h(b),$CLJS.DY)){var f=function(){var m=null==a?null:$CLJS.W0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.tQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,$CLJS.oj],null)),l=$CLJS.d5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,U=S;S=m7(S,l)?$CLJS.R.j(U,$CLJS.q_,!0):U;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return m7(G,l)?$CLJS.R.j(K,$CLJS.q_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Sa(function(){if($CLJS.n(f)){var t=$CLJS.si.h(k);return $CLJS.n(t)?$CLJS.Bj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.$i)?$CLJS.gk.g(l7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.e5(),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.MD("Bin every 0.1 degrees"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,.1],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.MD("Bin every 1 degree"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,1],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.MD("Bin every 10 degrees"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,10],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.MD("Bin every 20 degrees"),$CLJS.mD,new $CLJS.h(null,
2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.oj)&&!$CLJS.Dz(e,$CLJS.$h)?$CLJS.gk.g(l7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.e5(),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.MD("10 bins"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.lD,$CLJS.lD,10],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.MD("50 bins"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.lD,$CLJS.lD,50],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.MD("100 bins"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.lD,$CLJS.lD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.nV.m(null,$CLJS.mF,function(a){return a});
$CLJS.nV.m(null,$CLJS.NJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.OJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.AD,$CLJS.p.h($CLJS.LD()),$CLJS.oi,$CLJS.zz($CLJS.oi,$CLJS.tA)(a),$CLJS.BK,$CLJS.T.h(a)],null),a=$CLJS.A0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bF,b,a],null);case "source/expressions":return k7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.rD.h(a))?k7(a):t7(a);default:return t7(a)}});
$CLJS.xta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.gk.g($CLJS.pV,k));var l=sta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.ul(function(t){return $CLJS.S1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.fk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.pV),m):null;return $CLJS.PV.l(e,f,$CLJS.HD,$CLJS.H([$CLJS.UD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.z7=function(){function a(d,e){return $CLJS.UD.h($CLJS.EV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.yta=function(){function a(d,e){var f=$CLJS.V_.v(d,e,$CLJS.EV(d,e),new $CLJS.h(null,3,[$CLJS.C_,!1,$CLJS.I_,!1,$CLJS.y_,!1],null)),k=$CLJS.z7.g(d,e);return $CLJS.od(k)?$CLJS.gk.g(function(l){return $CLJS.R.j(l,$CLJS.KY,!0)},f):$CLJS.e3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(v7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.cj,$CLJS.yY,$CLJS.cz],null));
$CLJS.Y(uta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.u7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,v7],null)],null)],null));